import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/typography.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "react-bootstrap-accordion/dist/index.css";
import AppRouter from "./router/AppRouter";
function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
