import React, { useEffect, useState } from "react";
import { MESSAGE, NOIMG } from "../../AppUtilities";
import * as API from "../../api/index";
import { useLocation } from "react-router";
import { Accordion } from "react-bootstrap-accordion";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AWS3, IMG } from "../../api/constant";
const UserDetails = () => {
  const loaction = useLocation();
  console.log("loaction.state.type", loaction.state.type);
  const [allData, setAllData] = useState([]);
  const [allDoc, setAllDoc] = useState([]);
  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        id: loaction.state.id,
        type: loaction.state.type,
      };
      const response = await API.allUserbyid(reqObj, header);
      console.log("userDetails", response);
      if (response.data.success) {
        setAllDoc(response.data.user.documents);
        setAllData(response.data.user);
      }
    } catch (error) {}
  };
  const fullURLs = allDoc.map((doc) => `${AWS3}${doc}`);
  console.log("fullURLs", fullURLs);
  // const zipAndDownloadFiles = async () => {
  //   const zip = new JSZip();

  //   // Fetch and add files to the zip
  //   for (const fileUrl of fullURLs) {
  //     try {
  //       const response = await fetch(fileUrl);
  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);
  //       }
  //       const blob = await response.blob();
  //       const fileName = fileUrl.split("/").pop();
  //       zip.file(fileName, blob);
  //     } catch (error) {
  //       console.error("Error fetching file:", error);
  //     }
  //   }

  //   // Generate the zip file and download it
  //   try {
  //     const content = await zip.generateAsync({ type: "blob" });
  //     saveAs(content, "documents.zip");
  //   } catch (error) {
  //     console.error("Error generating zip file:", error);
  //   }
  // };

  const zipAndDownloadFiles = async () => {
    const zip = new JSZip();

    // Fetch and add files to the zip
    for (const fileUrl of fullURLs) {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileName = fileUrl.split("/").pop();
      zip.file(fileName, blob);
    }

    // Generate the zip file and download it
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "documents.zip");
    });
  };
  const userStatusChanges = async (data) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        isActive: data,
        id: loaction.state.id,
      };
      console.log("reqObj", reqObj);
      const response = await API.userStatusChnages(reqObj, header);
      console.log("response", response);
      if (response.data.success) {
        userDataGetById();
        MESSAGE(response.data.message, 1);
      }
    } catch (error) {}
  };

  useEffect(() => {
    userDataGetById();
  }, []);
  return (
    <>
      {" "}
      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row profile-content">
            <div class="col-12 col-md-12 col-lg-4">
              <div class="iq-card">
                <div class="iq-card-body profile-page">
                  <div class="profile-header">
                    <div class="cover-container text-center">
                      <img
                        src={
                          Object.keys(allData).includes("profile_pic") &&
                          allData.profile_pic
                            ? allData.profile_pic
                            : NOIMG
                        }
                        alt="profile-bg"
                        class="rounded-circle img-fluid"
                      />
                      <div class="profile-detail mt-3">
                        <h3>
                          {loaction.state.type === "driver" ? (
                            allData.fullName
                          ) : (
                            <>
                              {allData.firstName} {allData.lastName}
                            </>
                          )}
                        </h3>
                        <h6 class="text-capitalize text-danger">
                          {allData.role}
                        </h6>
                        <p class="text-primary mb-2">{allData.phoneNumber}</p>

                        {loaction.state.type === "driver" ? (
                          <>
                            <h6>{allData.email}</h6>
                            <button
                              className="btn btn-primary mr-3"
                              onClick={() => userStatusChanges(true)}
                            >
                              Approve
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => userStatusChanges(false)}
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="iq-social d-none  align-items-center">
                        <ul class="list-inline d-flex p-0 mb-0 align-items-center">
                          <li>
                            <a
                              href="#"
                              class="avatar-40 rounded-circle bg-primary mr-2 facebook"
                            >
                              <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="avatar-40 rounded-circle bg-primary mr-2 twitter"
                            >
                              <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="avatar-40 rounded-circle bg-primary mr-2 youtube"
                            >
                              <i
                                class="fa fa-youtube-play"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="avatar-40 rounded-circle bg-primary pinterest"
                            >
                              <i
                                class="fa fa-pinterest-p"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class={loaction.state.type === "driver" ? "iq-card" : "d-none"}
              >
                <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div class="iq-header-title">
                    <h4 class="card-title mb-0">Personal Details</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <ul class="list-inline p-0 mb-0">
                    <li>
                      <div class="row align-items-center justify-content-between mb-3">
                        <div class="col-sm-6">
                          <h6>Address</h6>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0">{allData.addresses}</p>
                        </div>
                      </div>
                    </li>

                    {/* <li>
                      <div class="row align-items-center justify-content-between mb-3">
                        <div class="col-sm-6">
                          <h6>Email</h6>
                        </div>
                        <div class="col-sm-6">
                          <p class="mb-0">{allData.email}</p>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8">
              <div class="row">
                <div class="col-md-12">
                  <div
                    class={
                      loaction.state.type === "driver" ? "iq-card" : "d-none"
                    }
                  >
                    <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div class="iq-header-title">
                        <h4 class="card-title mb-0">Car Details</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <ul class="list-inline p-0 mb-0">
                        {loaction.state.type === "driver" ? (
                          <li>
                            <div class="row align-items-center justify-content-between mb-3">
                              <div class="col-sm-6">
                                <h6>Document</h6>
                              </div>
                              <div class="col-sm-6">
                                <p class="mb-0">
                                  {allDoc.length === 0 ? (
                                    "N/A"
                                  ) : (
                                    <button
                                      className="btn btn-primary"
                                      onClick={zipAndDownloadFiles}
                                    >
                                      Download
                                    </button>
                                  )}
                                </p>
                              </div>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Driving License Number</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.dln}</p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Social security number</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.ssn}</p>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Model of the car</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.dln}</p>
                            </div>
                          </div>
                        </li> */}
                        {/* <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Color of the car</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.phoneNumber}</p>
                            </div>
                          </div>
                        </li> */}
                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Insurance Coverage</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.ic}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Type of Coverage</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.tc}</p>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Number of passanger</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.dln}</p>
                            </div>
                          </div>
                        </li> */}
                        {/* <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Size of Car</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.dln}</p>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                    class={
                      loaction.state.type === "driver" ? "d-none " : "iq-card"
                    }
                  >
                    <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div class="iq-header-title">
                        <h4 class="card-title mb-0">Personal Details</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <ul class="list-inline p-0 mb-0">
                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Address</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">N/A</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Mobile Number</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.phoneNumber}</p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row align-items-center justify-content-between mb-3">
                            <div class="col-sm-6">
                              <h6>Email</h6>
                            </div>
                            <div class="col-sm-6">
                              <p class="mb-0">{allData.email}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div class="iq-header-title">
                        <h4 class="card-title mb-0">Latest Uploads</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <ul class="list-inline p-0 mb-0">
                        <li class="d-flex mb-4 align-items-center">
                          <div class="profile-icon bg-secondary">
                            <span>
                              <i class="ri-file-3-fill"></i>
                            </span>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>Documentation</h6>
                            <p class="mb-0">48kb</p>
                          </div>
                          <div class="iq-card-toolbar">
                            <div class="dropdown">
                              <span
                                class="font-size-24"
                                id="dropdownMenuButton01"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-line"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right p-0">
                                <a class="dropdown-item" href="#">
                                  <i class="ri-user-unfollow-line mr-2"></i>
                                  Share
                                </a>
                                <a class="dropdown-item" href="#">
                                  <i class="ri-close-circle-line mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="d-flex mb-4 align-items-center">
                          <div class="profile-icon bg-secondary">
                            <span>
                              <i class="ri-image-fill"></i>
                            </span>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>Images</h6>
                            <p class="mb-0">204kb</p>
                          </div>
                          <div class="iq-card-toolbar">
                            <div class="dropdown">
                              <span
                                class="font-size-24"
                                id="dropdownMenuButton02"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-line"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right p-0">
                                <a class="dropdown-item" href="#">
                                  <i class="ri-user-unfollow-line mr-2"></i>
                                  Share
                                </a>
                                <a class="dropdown-item" href="#">
                                  <i class="ri-close-circle-line mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="d-flex mb-4 align-items-center">
                          <div class="profile-icon bg-secondary">
                            <span>
                              <i class="ri-video-fill"></i>
                            </span>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>Videos</h6>
                            <p class="mb-0">509kb</p>
                          </div>
                          <div class="iq-card-toolbar">
                            <div class="dropdown">
                              <span
                                class="font-size-24"
                                id="dropdownMenuButton03"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-line"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right p-0">
                                <a class="dropdown-item" href="#">
                                  <i class="ri-user-unfollow-line mr-2"></i>
                                  Share
                                </a>
                                <a class="dropdown-item" href="#">
                                  <i class="ri-close-circle-line mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="d-flex mb-4 align-items-center">
                          <div class="profile-icon bg-secondary">
                            <span>
                              <i class="ri-file-3-fill"></i>
                            </span>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>Resources</h6>
                            <p class="mb-0">48kb</p>
                          </div>
                          <div class="iq-card-toolbar">
                            <div class="dropdown">
                              <span
                                class="font-size-24"
                                id="dropdownMenuButton04"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-line"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right p-0">
                                <a class="dropdown-item" href="#">
                                  <i class="ri-user-unfollow-line mr-2"></i>
                                  Share
                                </a>
                                <a class="dropdown-item" href="#">
                                  <i class="ri-close-circle-line mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="d-flex align-items-center">
                          <div class="profile-icon bg-secondary">
                            <span>
                              <i class="ri-refresh-line"></i>
                            </span>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>Celine Dion</h6>
                            <p class="mb-0">204kb</p>
                          </div>
                          <div class="iq-card-toolbar">
                            <div class="dropdown">
                              <span
                                class="font-size-24"
                                id="dropdownMenuButton05"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-line"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right p-0">
                                <a class="dropdown-item" href="#">
                                  <i class="ri-user-unfollow-line mr-2"></i>
                                  Share
                                </a>
                                <a class="dropdown-item" href="#">
                                  <i class="ri-close-circle-line mr-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                <div class="col-md-6 d-none">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div class="iq-header-title">
                        <h4 class="card-title mb-0">Top Books</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <ul class="list-inline p-0 mb-0">
                        <li>
                          <div class="iq-details mb-2">
                            <span class="title">Adventure</span>
                            <div class="percentage float-right text-primary">
                              95 <span>%</span>
                            </div>
                            <div class="iq-progress-bar-linear d-inline-block w-100">
                              <div class="iq-progress-bar">
                                <span
                                  class="bg-primary"
                                  data-percent="95"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="iq-details mb-2">
                            <span class="title">Horror</span>
                            <div class="percentage float-right text-warning">
                              72 <span>%</span>
                            </div>
                            <div class="iq-progress-bar-linear d-inline-block w-100">
                              <div class="iq-progress-bar">
                                <span
                                  class="bg-warning"
                                  data-percent="72"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="iq-details mb-2">
                            <span class="title">Comic Book</span>
                            <div class="percentage float-right text-info">
                              75 <span>%</span>
                            </div>
                            <div class="iq-progress-bar-linear d-inline-block w-100">
                              <div class="iq-progress-bar">
                                <span class="bg-info" data-percent="75"></span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="iq-details mb-2">
                            <span class="title">Biography</span>
                            <div class="percentage float-right text-danger">
                              70 <span>%</span>
                            </div>
                            <div class="iq-progress-bar-linear d-inline-block w-100">
                              <div class="iq-progress-bar">
                                <span
                                  class="bg-danger"
                                  data-percent="70"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="iq-details">
                            <span class="title">Mystery</span>
                            <div class="percentage float-right text-success">
                              80 <span>%</span>
                            </div>
                            <div class="iq-progress-bar-linear d-inline-block w-100">
                              <div class="iq-progress-bar">
                                <span
                                  class="bg-success"
                                  data-percent="80"
                                ></span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div class="iq-header-title">
                    <h4 class="card-title mb-0">Ride History ( Coming soon)</h4>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="dropdown">
                      <span
                        class="dropdown-toggle text-primary"
                        id="dropdownMenuButton05"
                        data-toggle="dropdown"
                      >
                        <i class="ri-more-fill"></i>
                      </span>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton05"
                      >
                        <a class="dropdown-item" href="#">
                          <i class="ri-eye-fill mr-2"></i>View
                        </a>
                        <a class="dropdown-item" href="#">
                          <i class="ri-delete-bin-6-fill mr-2"></i>Delete
                        </a>
                        <a class="dropdown-item" href="#">
                          <i class="ri-pencil-fill mr-2"></i>Edit
                        </a>
                        <a class="dropdown-item" href="#">
                          <i class="ri-printer-fill mr-2"></i>Print
                        </a>
                        <a class="dropdown-item" href="#">
                          <i class="ri-file-download-fill mr-2"></i>Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="iq-card-body">
                  {/* <Accordion title="Kolkata,DumDum">
                    <p>Keojldhkjdfk fmzfjsbj</p>
                  </Accordion> */}

                  <ul class="perfomer-lists m-0 p-0">
                    <li class="row mb-3 align-items-center justify-content-between">
                      <div class="col-md-8">
                        <h5>Kolkata,DumDum</h5>
                        <p class="mb-0">General Book</p>
                      </div>
                      <div class="col-md-4 text-right">
                        <div class="iq-card-header-toolbar d-flex align-items-center">
                          <span class="text-primary mr-3">
                            <b>+$92</b>
                          </span>
                          <div class="iq-progress-bar-linear d-inline-block mt-1 w-100">
                            <div class="iq-progress-bar iq-bg-primary">
                              <span class="bg-primary" data-percent="92"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="row align-items-center justify-content-between">
                      <div class="col-md-8">
                        <h5>
                          1701 Valley View Dr, Birmingham, AL 35209, United
                          States
                        </h5>
                      </div>
                    </li>
                    <li class="row align-items-center justify-content-between mt-3">
                      <div class="col-md-8">
                        <h5>25 July 2024</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
