import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../component/Header";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Dashboard from "../pages/Dashboard";

import AddAdmin from "../pages/ManageAdmin/AddAdmin";
import Roles from "../pages/AppUsers/ManagePassenger/PassengerList";
import AddRole from "../pages/ManageAdmin/AddRole";
import Company from "../pages/ManageCompany/Company";
import AddCompany from "../pages/ManageCompany/AddCompany";

import ManualBooking from "../pages/ManageManualBooking/ManualBooking";
import VehicleMake from "../pages/VehicleMake";
import AddVehicle from "../pages/AddVehicle";
import VehicleModel from "../pages/VehicleModel";
import AddVehicleModel from "../pages/AddVehicleModel";
import Vehicle from "../pages/ManageVehiclesType/Vehicle";
import AddVehicles from "../pages/ManageVehiclesType/AddVehicles";
import VehicleTypes from "../pages/ManageVehiclesType/VehicleTypes";
import AddVehicleTypes from "../pages/ManageVehiclesType/AddVehicleTypes";
import AdditionalReasons from "../pages/AdditionalReasons/AdditionalReasons";
import AddAdditionalReason from "../pages/AdditionalReasons/AddAdditionalReason";
import CancelReason from "../pages/ManageCancelReason/CancelReason";
import AddCancelReason from "../pages/ManageCancelReason/AddCancelReason";
import Locations from "../pages/ManageLocations/Locations";
import AddLocations from "../pages/ManageLocations/AddLocations";
import ManageFare from "../pages/ManageFare/ManageFare";
import AddManageFare from "../pages/ManageFare/AddManageFare";
import ManageRideRequests from "../pages/ManageRideRequests";
import ManageTrips from "../pages/ManageTrips";
import ManageCanceledTrips from "../pages/ManageCanceledTrips";
import ManagePayments from "../pages/ManagePayments";
import CompanyPayouts from "../pages/ManagePayouts/CompanyPayouts";
import DriverPayouts from "../pages/ManagePayouts/DriverPayouts";
import Ratings from "../pages/Ratings";
import OweAmount from "../pages/OweAmount";
import Overall from "../pages/ManageStatements/Overall";
import DriversStatments from "../pages/ManageStatements/DriversStatments";
import ManageWallet from "../pages/ManageWalletPromo/ManageWallet";
import AddWallet from "../pages/ManageWalletPromo/AddWallet";
import PromoCode from "../pages/ManageWalletPromo/PromoCode";
import AddPromoCode from "../pages/ManageWalletPromo/AddPromoCode";
import Referral from "../pages/Referral";
import ReferralDriver from "../pages/ReferralDriver";
import MapView from "../pages/ManageMap/MapView";
import MobileApp from "../pages/MobileAppVersion/MobileApp";
import AddMobileApp from "../pages/MobileAppVersion/AddMobileApp";
import ApiCredentials from "../pages/ApiCredentials";
import PaymentGateway from "../pages/PaymentGateway";
import SiteSettings from "../pages/SiteSettings";
import SignInValidation from "../pages/SignInValidation";
import Footer from "../component/Footer";
import { ToastContainer } from "react-toastify";
import * as API from "../api/index";
import PassengerList from "../pages/AppUsers/ManagePassenger/PassengerList";
import DriverList from "../pages/AppUsers/ManageDriver/DriverList";
import UserDetails from "../pages/AppUsers/UserDetails";
const AppRouter = () => {
  const [isLogin, setIsLogin] = useState(
    JSON.parse(localStorage.getItem("_isLogin"))
  );

  const [user, setUser] = useState([]);

  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.userDetailsId(header);
      setUser(response.data.data);
      console.log("userType", response);
    } catch (error) {}
  };
  useEffect(() => {
    userDataGetById();
  }, []);
  return (
    <>
      <ToastContainer />
      <Router>
        {isLogin ? (
          <>
            <Header setIsLogin={setIsLogin} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              {/* ? DRIVER */}
              <Route path="/driver-list" element={<DriverList user={user} />} />
              <Route path="/passenger-list" element={<PassengerList />} />
              <Route path="/app-user/:slug" element={<UserDetails />} />

              {/* <Route path="/add-role" element={<AddRole />} />
              <Route path="/company" element={<Company />} />
              <Route path="/add-company" element={<AddCompany />} />
              <Route path="/driver" element={<Driver />} />
              <Route path="/add-driver" element={<AddDriver />} />
              <Route path="/manual-booking" element={<ManualBooking />} />
              <Route path="/vehicle-make" element={<VehicleMake />} />
              <Route path="/add-vehicle" element={<AddVehicle />} />
              <Route path="/vehicle-model" element={<VehicleModel />} />
              <Route path="/add-vehicle-model" element={<AddVehicleModel />} />
              <Route path="/vehicle" element={<Vehicle />} />
              <Route path="/add-vehicles" element={<AddVehicles />} />
              <Route path="/vehicle-types" element={<VehicleTypes />} />
              <Route path="/add-vehicle-types" element={<AddVehicleTypes />} />
              <Route
                path="/additional-reasons"
                element={<AdditionalReasons />}
              />
              <Route
                path="/add-additional-reasons"
                element={<AddAdditionalReason />}
              />
              <Route path="/cancel-reason" element={<CancelReason />} />
              <Route path="/add-cancel-reason" element={<AddCancelReason />} />
              <Route path="/locations" element={<Locations />} />
              <Route path="/add-locations" element={<AddLocations />} />
              <Route path="/manage-fare" element={<ManageFare />} />
              <Route path="/add-manage-fare" element={<AddManageFare />} />
              <Route
                path="/manage-ride-requests"
                element={<ManageRideRequests />}
              />
              <Route path="/manage-trips" element={<ManageTrips />} />
              <Route
                path="/manage-canceled-trips"
                element={<ManageCanceledTrips />}
              />
              <Route path="/manage-payments" element={<ManagePayments />} />
              <Route path="/company-payouts" element={<CompanyPayouts />} />
              <Route path="/driver-payouts" element={<DriverPayouts />} />
              <Route path="/ratings" element={<Ratings />} />
              <Route path="/owe-amount" element={<OweAmount />} />
              <Route path="/overall" element={<Overall />} />
              <Route path="/drivers-statments" element={<DriversStatments />} />
              <Route path="/manage-wallet" element={<ManageWallet />} />
              <Route path="/add-wallet" element={<AddWallet />} />
              <Route path="/promo-code" element={<PromoCode />} />
              <Route path="/add-promo-code" element={<AddPromoCode />} />
              <Route path="/referral" element={<Referral />} />
              <Route path="/referral-driver" element={<ReferralDriver />} />
              <Route path="/map-view" element={<MapView />} />
              <Route path="/mobile-app" element={<MobileApp />} />
              <Route path="/add-mobile-app" element={<AddMobileApp />} />
              <Route path="/api-credentials" element={<ApiCredentials />} />
              <Route path="/payment-gateway" element={<PaymentGateway />} />
              <Route path="/site-settings" element={<SiteSettings />} /> */}
            </Routes>
            <Footer />
          </>
        ) : (
          <Routes>
            <Route path="/" element={<SignIn setIsLogin={setIsLogin} />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Routes>
        )}
      </Router>
    </>
  );
};

export default AppRouter;
