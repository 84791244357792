import { toast } from "react-toastify";
import logo from "./assets/images/logo.png";
import sidebarbottoms from "./assets/images/page-img/side-bkg.png";
import userIcons from "./assets/images/user/01.jpg";
import avter from "./assets/images/avatar-blank.png";
import "react-toastify/dist/ReactToastify.css";
export const logos = logo;
export const sidebarbottom = sidebarbottoms;
export const userIcon = userIcons;
export const NOIMG = avter;

export const headerToken = localStorage.getItem("_tokenCode");

// ? TOTAL APLICATION SUCCESS AND ERROR MESSAGE
export const MESSAGE = (data, status) => {
  toast(data, {
    position: "top-right",
    autoClose: 5000,
    type: status === 1 ? "success" : "error",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
