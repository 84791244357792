export const CURRENCY = "$";
export const TIMEZONE = "America/Los_Angeles";

export const IMG = "http://3.143.229.208:8080/";
export const AWS3 = "https://vep-s3.s3.us-east-2.amazonaws.com/";

// ? ====== BASE URL ======

export const AURL = "http://3.143.229.208:8080/api/v1/admin";
export const USERURL = "http://3.143.229.208:8080/api/v1/user";
export const SURL = "http://54.208.169.69:8080";
//export const URL = "http://192.168.1.158:3000/api";
