import React, { useState } from "react";
import { NOIMG, userIcon } from "../AppUtilities";
import { logos } from "../AppUtilities";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";

const Header = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const logoutButton = () => {
    localStorage.removeItem("_tokenCode");
    localStorage.removeItem("_isLogin");
    setIsLogin(localStorage.removeItem("_isLogin"));
    if (localStorage.getItem("_isLogin") === null) {
      navigate("/");
    }
  };
  return (
    <>
      <div class="wrapper">
        <div class="iq-sidebar">
          <div id="sidebar-scrollbar">
            <Sidebar collapsed={isOpen}>
              <div class="iq-sidebar-logo d-flex justify-content-between">
                <a href="" class="header-logo">
                  <img src={logos} class="img-fluid rounded-normal" alt="" />
                  {/* <div class="logo-title">
                     <span class="text-primary text-uppercase"><img src={logos} class="img-fluid rounded-normal" alt=""/></span>
                  </div> */}
                </a>
              </div>
              <Menu>
                <MenuItem component={<NavLink to="/dashboard" />}>
                  <i class="las la-tachometer-alt"></i> Dashboard{" "}
                </MenuItem>
                <SubMenu
                  label="Manage Users"
                  icon={<i class="las la-user-cog"></i>}
                >
                  <MenuItem component={<NavLink to="/driver-list" />}>
                    <i class="ri-record-circle-line"></i> Drivers
                  </MenuItem>
                  <MenuItem component={<NavLink to="/passenger-list" />}>
                    <i class="ri-record-circle-line"></i> Passengers{" "}
                  </MenuItem>
                </SubMenu>
                {/* <MenuItem> Documentation </MenuItem>
                <MenuItem> Calendar </MenuItem> */}
              </Menu>
            </Sidebar>
          </div>
        </div>

        <div class="iq-top-navbar">
          <div class="iq-navbar-custom">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              {/* <div class="iq-menu-bt d-flex align-items-center">
                <div class="wrapper-menu">
                  <div class="main-circle" onClick={() => setIsOpen(!isOpen)}>
                    <i class="las la-bars"></i>
                  </div>
                </div>
              </div> */}
              <div class="navbar-breadcrumb">
                <h5 class="mb-0">Dashbord</h5>
                <nav aria-label="breadcrumb">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Admin</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashbord
                    </li>
                  </ul>
                </nav>
              </div>

              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-label="Toggle navigation"
              >
                <i class="ri-menu-3-line"></i>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto navbar-list">
                  {/* <li class="nav-item nav-icon search-content">
                    <a
                      href="#"
                      class="search-toggle iq-waves-effect text-gray rounded"
                    >
                      <i class="ri-search-line"></i>
                    </a>
                    <form action="#" class="search-box p-0">
                      <input
                        type="text"
                        class="text search-input"
                        placeholder="Type here to search..."
                      />
                      <a class="search-link" href="#">
                        <i class="ri-search-line"></i>
                      </a>
                    </form>
                  </li>
                  <li class="nav-item nav-icon">
                    <a
                      href="#"
                      class="search-toggle iq-waves-effect text-gray rounded"
                    >
                      <i class="ri-notification-2-line"></i>
                      <span class="bg-primary dots"></span>
                    </a>
                    <div class="iq-sub-dropdown">
                      <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0">
                          <div class="bg-primary p-3">
                            <h5 class="mb-0 text-white">
                              All Notifications
                              <small class="badge  badge-light float-right pt-1">
                                4
                              </small>
                            </h5>
                          </div>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Emma Watson Barry</h6>
                                <small class="float-right font-size-12">
                                  Just Now
                                </small>
                                <p class="mb-0">95 MB</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/02.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">New customer is join</h6>
                                <small class="float-right font-size-12">
                                  5 days ago
                                </small>
                                <p class="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Two customer is left</h6>
                                <small class="float-right font-size-12">
                                  2 days ago
                                </small>
                                <p class="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">New Mail from Fenny</h6>
                                <small class="float-right font-size-12">
                                  3 days ago
                                </small>
                                <p class="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item nav-icon dropdown">
                    <a
                      href="#"
                      class="search-toggle iq-waves-effect text-gray rounded"
                    >
                      <i class="ri-mail-line"></i>
                      <span class="bg-primary dots"></span>
                    </a>
                    <div class="iq-sub-dropdown">
                      <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                          <div class="bg-primary p-3">
                            <h5 class="mb-0 text-white">
                              All Messages
                              <small class="badge  badge-light float-right pt-1">
                                5
                              </small>
                            </h5>
                          </div>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Barry Emma Watson</h6>
                                <small class="float-left font-size-12">
                                  13 Jun
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/02.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Lorem Ipsum Watson</h6>
                                <small class="float-left font-size-12">
                                  20 Apr
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Why do we use it?</h6>
                                <small class="float-left font-size-12">
                                  30 Jun
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Variations Passages</h6>
                                <small class="float-left font-size-12">
                                  12 Sep
                                </small>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="avatar-40 rounded"
                                  src="images/user/05.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Lorem Ipsum generators</h6>
                                <small class="float-left font-size-12">
                                  5 Dec
                                </small>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item nav-icon dropdown">
                    <a
                      href="#"
                      class="search-toggle iq-waves-effect text-gray rounded"
                    >
                      <i class="ri-shopping-cart-2-line"></i>
                      <span class="badge badge-danger count-cart rounded-circle">
                        4
                      </span>
                    </a>
                    <div class="iq-sub-dropdown">
                      <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 toggle-cart-info">
                          <div class="bg-primary p-3">
                            <h5 class="mb-0 text-white">
                              All Carts
                              <small class="badge  badge-light float-right pt-1">
                                4
                              </small>
                            </h5>
                          </div>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="rounded"
                                  src="images/cart/01.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Night People book</h6>
                                <p class="mb-0">$32</p>
                              </div>
                              <div class="float-right font-size-24 text-danger">
                                <i class="ri-close-fill"></i>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="rounded"
                                  src="images/cart/02.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">The Sin Eater Book</h6>
                                <p class="mb-0">$40</p>
                              </div>
                              <div class="float-right font-size-24 text-danger">
                                <i class="ri-close-fill"></i>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="rounded"
                                  src="images/cart/03.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">the Orange Tree</h6>
                                <p class="mb-0">$30</p>
                              </div>
                              <div class="float-right font-size-24 text-danger">
                                <i class="ri-close-fill"></i>
                              </div>
                            </div>
                          </a>
                          <a href="#" class="iq-sub-card">
                            <div class="media align-items-center">
                              <div class="">
                                <img
                                  class="rounded"
                                  src="images/cart/04.jpg"
                                  alt=""
                                />
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Harsh Reality book</h6>
                                <p class="mb-0">$25</p>
                              </div>
                              <div class="float-right font-size-24 text-danger">
                                <i class="ri-close-fill"></i>
                              </div>
                            </div>
                          </a>
                          <div class="d-flex align-items-center text-center p-3">
                            <a
                              class="btn btn-primary mr-2 iq-sign-btn"
                              href="#"
                              role="button"
                            >
                              View Cart
                            </a>
                            <a
                              class="btn btn-primary iq-sign-btn"
                              href="#"
                              role="button"
                            >
                              Shop now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}

                  <li class="line-height users pt-3">
                    <NavLink
                      to="#"
                      className="search-toggle iq-waves-effect d-flex align-items-center"
                    >
                      <img
                        src={NOIMG}
                        class="img-fluid rounded-circle mr-3"
                        alt="user"
                      />
                    </NavLink>
                    <div class="iq-sub-dropdown iq-user-dropdown">
                      <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0 ">
                          <div class="bg-primary p-3">
                            <h5 class="mb-0 text-white line-height">Admin</h5>
                            <span class="text-white font-size-12">
                              Available
                            </span>
                          </div>
                          <Link to="#" class="iq-sub-card iq-bg-primary-hover">
                            <div class="media align-items-center">
                              <div class="rounded iq-card-icon iq-bg-primary">
                                <i class="ri-file-user-line"></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">My Profile</h6>
                                <p class="mb-0 font-size-12">
                                  View personal profile details.
                                </p>
                              </div>
                            </div>
                          </Link>
                          <Link to="#" class="iq-sub-card iq-bg-primary-hover">
                            <div class="media align-items-center">
                              <div class="rounded iq-card-icon iq-bg-primary">
                                <i class="ri-profile-line"></i>
                              </div>
                              <div class="media-body ml-3">
                                <h6 class="mb-0 ">Edit Profile</h6>
                                <p class="mb-0 font-size-12">
                                  Modify your personal details.
                                </p>
                              </div>
                            </div>
                          </Link>

                          <div class="d-inline-block w-100 text-center p-3">
                            <span
                              onClick={logoutButton}
                              class="bg-primary iq-sign-btn"
                              role="button"
                            >
                              Sign out<i class="ri-login-box-line ml-2"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
