import axios from "axios";
import * as c from "../api/constant";

export const admin_login = async (data) => {
  try {
    const url = c.AURL + "/admin-login";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allUserByadmin = async (data, header) => {
  try {
    const url = c.AURL + "/all-users?role=admin&searchRole=" + data;
    console.log("url", url);
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allUser = async (header) => {
  try {
    const url = c.AURL + "/all-app-users";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const allUserbyid = async (data, header) => {
  try {
    const url =
      data.type === "driver"
        ? c.AURL + "/findDriverById?id=" + data.id
        : c.AURL + "/findpassengerById?id=" + data.id;
    console.log("url", url);
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const userStatusChnages = async (data, header) => {
  try {
    const url =
      c.AURL + "/updateDriverUser?id=" + data.id + "&isActive=" + data.isActive;
    console.log("url", url);
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const blogDriverUser = async (data, header) => {
  try {
    const url =
      c.AURL +
      "/blockDriverUser?id=" +
      data.id +
      "&is_blocked=" +
      data.isActive;
    console.log("url", url);
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const blockPassengerUser = async (data, header) => {
  try {
    const url = c.AURL + "/block-user/" + data.id;
    console.log("url", url);
    const res = await axios.put(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const userDetailsId = async (header) => {
  try {
    const url = c.USERURL + "/get-profile";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
