import React, { useEffect, useState } from "react";
import { MESSAGE, NOIMG, userIcon } from "../../../AppUtilities";
import { Link } from "react-router-dom";
import * as API from "../../../api/index";
import { BeatLoader } from "react-spinners";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
const DriverList = ({ user }) => {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [userid, setUserid] = useState("");

  const onOpenModal = (data) => {
    setUserid(data);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.allUser(header);
      if (response.data.success) {
        setLoader(false);
        setAllData(response.data.data.driver);
        console.log("Editresponse", response);
      }
    } catch (error) {}
  };

  const userStatusChanges = async (data) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        isActive: data,
        id: userid,
      };
      console.log("reqObj", reqObj);
      const response = await API.blogDriverUser(reqObj, header);
      console.log("response", response);
      if (response.data.success) {
        userDataGetById();
        setOpen(false);
        MESSAGE(response.data.message, 1);
      }
    } catch (error) {}
  };

  useEffect(() => {
    userDataGetById();
  }, []);

  return (
    <>
      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">All Drivers </h4>
                  </div>
                  <div class="iq-card-header-toolbar d-flex align-items-center">
                    <div class="form-group mb-0">
                      <input
                        type="search"
                        class="form-control"
                        id="exampleInputSearch"
                        placeholder="Search"
                        aria-controls="user-list-table"
                      />
                    </div>
                  </div>
                </div>
                <div class="iq-card-body">
                  <div class="table-responsive">
                    <table
                      id="user-list-table"
                      class="table table-striped table-bordered mt-4"
                      role="grid"
                      aria-describedby="user-list-page-info"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Profile</th>
                          <th>Name</th>
                          <th>Number</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {loader ? (
                        <div className="">
                          <BeatLoader />
                        </div>
                      ) : (
                        <tbody>
                          {allData.length === 0 ? (
                            <h4>Data Not Found</h4>
                          ) : (
                            allData.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td class="text-center">
                                  <img
                                    class="rounded img-fluid avatar-40"
                                    src={
                                      item.profile_pic === ""
                                        ? NOIMG
                                        : item.profile_pic
                                    }
                                    alt="profile"
                                  />
                                </td>
                                <td>
                                  {item.fullName === "" ? "N/A" : item.fullName}
                                </td>
                                <td>{item.phoneNumber}</td>
                                <td>{item.email}</td>
                                <td>
                                  {item.isActive ? (
                                    <span class="badge iq-bg-primary">
                                      Active
                                    </span>
                                  ) : (
                                    <span class="badge iq-bg-danger">
                                      In-Active
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <div class="flex align-items-center list-user-action">
                                    <Link
                                      class="iq-bg-primary"
                                      state={{ id: item._id, type: item.role }}
                                      to={`/app-user/${item.role}`}
                                    >
                                      <i class="bi bi-eye"></i>
                                    </Link>

                                    <a
                                      class="iq-bg-primary"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      data-original-title="Delete"
                                      href="#"
                                    >
                                      <i class="ri-delete-bin-line"></i>
                                    </a>

                                    <span
                                      onClick={() => onOpenModal(item._id)}
                                      className={
                                        item.is_blocked
                                          ? "iq-bg-danger"
                                          : "iq-bg-primary"
                                      }
                                    >
                                      {item.is_blocked ? (
                                        <i class="bi bi-lock-fill"></i>
                                      ) : (
                                        <i class="bi bi-check-circle-fill"></i>
                                      )}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <h4>Status change</h4>
        <p>Are you sure about immediately change status?</p>
        <div className="d-flex justify-content-center">
          <button
            onClick={() => userStatusChanges(false)}
            type="button"
            class="btn btn-primary mr-3 btn-lg"
          >
            Activate
          </button>
          <button
            onClick={() => userStatusChanges(true)}
            type="button"
            class="btn btn-danger btn-lg"
          >
            Deactivate
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DriverList;
