import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as API from "../api/index";
import { MESSAGE } from "../AppUtilities";
import { PulseLoader } from "react-spinners";
const initialState = {
  email: "",
  password: "",
};

const SignIn = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [isLoader, setisLoader] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const hendalerChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const signinDataSubmit = async () => {
    setisLoader(true);
    try {
      const reqObj = {
        email: formData.email,
        password: formData.password,
      };
      console.log("reqObj", reqObj);
      const response = await API.admin_login(reqObj);
      console.log("response", response);
      if (response.data.success) {
        localStorage.setItem("_role", response.data.user.role);
        const headerObj = {
          Authorization: `Bearer ${response.data.token}`,
          "Content-Type": "multipart/form-data",
        };
        localStorage.setItem("_tokenCode", JSON.stringify(headerObj));
        setisLoader(false);
        MESSAGE(response.data.message, 1);
        localStorage.setItem("_isLogin", true);
        setIsLogin(localStorage.getItem("_isLogin"));
        navigate("/dashboard");
      } else {
        setisLoader(false);
        MESSAGE(response.data.message);
      }
    } catch (error) {}
  };

  return (
    <>
      <section class="sign-in-page">
        <div class="container p-0">
          <div class="row no-gutters height-self-center">
            <div class="col-sm-12 align-self-center page-content rounded">
              <div class="row m-0">
                <div class="col-sm-12 sign-in-page-data">
                  <div class="sign-in-from bg-primary rounded">
                    <h3 class="mb-0 text-center text-white">Sign in</h3>
                    <p class="text-center text-white">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                    <div class="mt-4 form-text">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          type="email"
                          class="form-control mb-0"
                          placeholder="Enter email"
                          value={formData.email}
                          onChange={hendalerChange}
                          name="email"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        {/* <a href="#" class="float-right text-dark">
                          Forgot password?
                        </a> */}
                        <input
                          type="password"
                          class="form-control mb-0"
                          placeholder="Password"
                          value={formData.password}
                          onChange={hendalerChange}
                          name="password"
                        />
                      </div>

                      <div class="sign-info text-center">
                        {isLoader ? (
                          <span class="btn btn-white d-block w-100 mb-2">
                            <PulseLoader size="10" color="#158f15" />
                          </span>
                        ) : (
                          <span
                            class="btn btn-white d-block w-100 mb-2"
                            onClick={signinDataSubmit}
                          >
                            {" "}
                            Sign in
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
